<template>
  <v-container
    ref="pageActions"
    fluid
    tag="section"
  >

    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Акції"
      color="accent"
      class="mb-16"
    >
      <!--Фильтры-->
      <v-row class="mx-2 mb-2">
        <v-col cols="12" md="3">
          <v-select
            return-object
            item-text="name"
            item-value="name"
            v-model="project"
            :items="projects"
            label="Проект"
            clearable
            @change="setQueryParam()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            return-object
            item-text="name"
            item-value="name"
            v-model="actionType"
            :items="actionsTypes"
            label="Тип акції"
            clearable
            @change="setQueryParam()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            return-object
            item-text="name"
            item-value="name"
            v-model="actionMechanic"
            :items="actionsMechanics"
            label="Механіка"
            clearable
            @change="setQueryParam()"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="mis"
            label="МІС"
            clearable
            @change="setQueryParam()"
          ></v-text-field>
        </v-col>
      </v-row>

      <!--Таблица-->
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              МІС
            </th>
            <th class="primary--text">
              Механіка
            </th>
            <th class="primary--text">
              Назва
            </th>
            <th class="primary--text">
              Тип
            </th>
            <th class="primary--text">
              Дата З
            </th>
            <th class="primary--text">
              Дата ПО
            </th>
            <th width="50"></th>
          </tr>
        </thead>

        <tbody v-if="actions.length">
          <tr v-for="(action, index) in actions" :key="action.id">
            <td>{{ action.id }}</td>
            <td>{{ action.mis_id }}</td>
            <td>{{ action.mechanic }}</td>
            <td>{{ action.name }}</td>
            <td>{{ action.action_type }}</td>
            <td>{{ action.date_from | formatDate }}</td>
            <td>{{ action.date_to | formatDate }}</td>
            <td>
              <span class="d-flex">
                <v-icon
                  v-if="isManager || isAdmin"
                  class="mr-4"
                  color="green"
                  @click="$router.push(`/actions/${action.id}/`)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="isAdmin"
                  color="error"
                  @click="deleteAction(action.id)"
                >
                  mdi-delete
                </v-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <!--Пагинация-->
      <div v-if="pagesCount > 1" class="text-center py-4">
        <v-pagination
          @input="setQueryParam(pageCurrent)"
          v-model="pageCurrent"
          :total-visible="9"
          :length="pagesCount"
        ></v-pagination>
      </div>

      <!--Диалоговое окно для удаления-->
      <DialogConfitmDelete ref="dialogConfirmDelete" />
      <Preloader v-if="isSending" />
    </material-card>

    <v-btn
      v-if="isManager || isAdmin"
      class="add-user__button"
      fab
      dark
      color="primary"
      to="/actions/add/"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>

  </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex'

  export default {
    name: 'RegularTablesView',
    data() {
      return {
        // Список акций
        actions: [],
        // Тецущий проект
        project: {},
        // Типы акций
        actionsTypes: [],
        // Текущий тип акций
        actionType: {},
        // Список механик
        actionsMechanics: [],
        // Текущая механика
        actionMechanic: {},
        // МИС
        mis: null,
        // Текущая страница
        pageCurrent: null,
        // Количество акций на странице
        pageLimitItems: 10,
        // Количество страниц
        pagesCount: 1,
        // Выполняется отправка на сервер
        isSending: false
      }
    },
    computed: {
      ...mapGetters(['isManager', 'isAdmin', 'projects']),
    },

    mounted() {
      this.init();
    },

    watch: {
      // Сделано через watch  чтобы отслеживать переходы вне страницы (например если пользователь нажмёт в меню /actions/)
      '$route': {
        handler() {
          this.getActions();
        },
        deep: true,
      }
    },

    methods: {
      ...mapMutations(['setSnackbar']),
      ...mapActions(['getProjects']),

      async init() {
        await this.getActionType();
        await this.getActionsMechanics();
        await this.getProjects();

        await this.getActions();
      },

      // Получить типы акций
      async getActionType() {
        try {
          const { data } = await HTTP.post('/get-list-action-type', { 
            lang_key: 'ua', 
            enabled: 1,
          });
          this.actionsTypes = data?.content?.type || [];
        } catch(error) {
          console.warn(error);
        }
      },

      // Получить механики акций
      async getActionsMechanics() {
        try {
          const { data } = await HTTP.post('/get-list-action-mechanic', { 
            lang_key: 'ua',
          });
          this.actionsMechanics = data?.content?.mechanic || [];
        } catch(error) {
          console.warn(error);
        }
      },

      // Получить список акций
      async getActions() {
        let { project = null, type = null, mechanic = null, mis = null, page = 1 } = this.$route.query;
        this.project = this.projects.find((el) => el.id == project);
        this.actionType = this.actionsTypes.find((el) => el.id == type);
        this.actionMechanic = this.actionsMechanics.find((el) => el.id == mechanic);
        this.mis = mis;
        this.pageCurrent = +page;

        try {
          let { data } = await HTTP.post('/get-list-actions', { 
            project_id: project,
            type_id: type,
            mechanic_id: mechanic,
            mis_id: mis,
            archive: "0/1",
            lang_key: "ua",
            limit: this.pageLimitItems,
            page,
          });
  
          this.actions = data?.content?.actions || [];
          this.pagesCount = data?.content?.pagination?.pages || 1;
        } catch(error) {
          console.warn(error);
        }
      },

      // Добавляем query параметры в урл
      setQueryParam(page = 1) {
        this.pageCurrent = page;
        let query = {
          project: this.project?.id,
          type: this.actionType?.id,
          mechanic: this.actionMechanic?.id,
          mis: this.mis,
          page: this.pageCurrent
        };

        for(let key in query) {
          if (!query[key] || (key === 'page' &&  query?.page <= 1)) {
            delete query[key];
          }
        };

        let query1 = JSON.stringify(query).split('"').join('');
        let query2 = JSON.stringify(this.$route.query).split('"').join('');

        if (query1 != query2) {
          this.$router.push({ query });
        }
        return query;
      },

      // Удалить акцию
      async deleteAction(actionId) {
        let isConfirmDelete = await this.$refs.dialogConfirmDelete.callDialog();
        if (!isConfirmDelete) return;
        this.isSending = true;

        try {
          await HTTP.post('/delete-action', { action_id: actionId });
          this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
          this.getActions();
        } catch(error) {
          console.warn(error);
          this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
        } finally {
          this.isSending = false;
        }
      },
    },
  }

</script>

<style>
.add-user__button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
</style>
